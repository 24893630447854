@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	scroll-behavior: smooth;
	vertical-align: baseline;
	/* background: transparent; */
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	background-color: #181818;
}
